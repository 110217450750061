<script setup lang="ts">
onMounted(async () => {
	try {
		//await $recaptcha.init();
	} catch (e) {
		console.error(e);
	}
})

</script>


<template>
	<div class="vh-100 d-flex justify-content-center align-items-center">
		<slot/>
	</div>
</template>

<style scoped>

</style>

